import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { CheckCircle, Frown, Lock, Mail, User } from 'react-feather'
import Input from '../../components/Input'
import UserStore from '../../stores/UserStore'
import { Link } from "react-router-dom";
import Logo from '../../components/Logo'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router';
import { LoadingOverlay } from '../../components/Loading';

const ResetPasswordPage = observer((props) => {

    const loading = UserStore.props.loading
    const resetPasswordIsOk = UserStore.props.resetPasswordIsOk

    const { match: { params } } = props;

    const { errors, values, setErrors, setFieldValue, handleSubmit, handleReset, resetForm } = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: null,
            password: null,
            password_confirmation: null,
            token: params.token
        },
        onSubmit: values => UserStore.resetPassword(values, () => handleReset(), setErrors),
        validateOnBlur: false,
        validateOnChange: false
    })

    // useEffect(() => {
    //     UserStore.loadResetToken(params.token)
    // }, [])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 min-h-screen shadow bg-gradient-blue  py-5">
                    <div className="row">
                        <div className="col-12 mb-3 text-center">
                            <Logo className="white-logo logo-ticket-page"></Logo>
                        </div>
                        <div className="d-flex jc w-100">
                            <div className="card col-xl-3 col-lg-4 col-md-5 col-sm-4 col-11 px-0" style={{ minHeight: '200px' }}>
                                <LoadingOverlay loading={loading} text={'Chargement en cours ...'}></LoadingOverlay>
                                <form className="card-body row">
                                    {
                                        !resetPasswordIsOk &&
                                        <>
                                            <div className="col-12 form-group floated mb-3">
                                                <p className="text-center">Veuillez saisir votre nouveau mot de passe</p>
                                            </div>
                                            <div className="col-12 form-group floated">
                                                <Input
                                                    name="email"
                                                    label="Email"
                                                    type="email"
                                                    value={values['email']}
                                                    className="form-control"
                                                    onChange={setFieldValue}
                                                    error={errors['email']}
                                                />
                                            </div>
                                            <div className="col-12 form-group floated">
                                                <Input
                                                    name="password"
                                                    label="Nouveau mot de passe"
                                                    type="password"
                                                    value={values['password']}
                                                    className="form-control"
                                                    onChange={setFieldValue}
                                                    error={errors['password']}
                                                />
                                            </div>
                                            <div className="col-12 form-group floated">
                                                <Input
                                                    name="password_confirmation"
                                                    label="Confirmation nouveau mot de passe"
                                                    type="password"
                                                    value={values['password_confirmation']}
                                                    className="form-control"
                                                    onChange={setFieldValue}
                                                    error={errors['password_confirmation']}
                                                />
                                            </div>
                                            <div className="col-12 form-group">
                                                <button onClick={handleSubmit} className="btn btn-primary btn-lg btn-block">Réinitialiser mon mot de passe</button>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <Link to="/login">Retour</Link>
                                            </div>
                                        </>
                                    }
                                    {
                                        resetPasswordIsOk && !loading &&
                                        <>

                                            <div className="col-12  text-center">
                                                <CheckCircle size={60} className="text-primary"></CheckCircle>
                                            </div>
                                            <div className="col-12 mt-2 text-center h4 mb-3">
                                                <span style={{ color: '#607d8b' }}>Votre mot de passe a bien été réinitialisé</span>
                                            </div>
                                            <div className="col-12 form-group">
                                                <Link to="/login" className="btn btn-primary btn-lg btn-block">Se connecter</Link>
                                            </div>

                                        </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default withRouter(ResetPasswordPage)