import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Redirect, Route,withRouter } from 'react-router-dom'
import UserStore from '../stores/UserStore'

const IsNotVerified = observer((props) => {

    const isVerified = UserStore.props.user.verified
    const { children } = props

    useEffect(() => {
        UserStore.initMobile()
    }, [props.location])

    console.log('Middleware ISNOTVERIFIED',!isVerified)

    return <Route
        render={({ location }) => {
            if (!isVerified) {
                return children
            } else {
                return <Redirect
                    to={{
                        pathname: "/dashboard",
                        state: { from: location }
                    }}
                />
            }
        }
        }></Route>
})

export default IsNotVerified