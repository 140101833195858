import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Frown, Lock, Mail } from 'react-feather'
import Input from '../../components/Input'
import { useHistory, Link } from "react-router-dom";
import Logo from '../../components/Logo';
import { withRouter } from 'react-router';
import TicketStore from "../../stores/TicketStore"
import { observer } from 'mobx-react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import UserStore from '../../stores/UserStore';
import { Modal, ModalBody } from 'reactstrap';
import { X } from 'react-feather';
import { currencyFormat, numberFormat } from '../../utils';

const AuthModal = (props) => {

    const { isOpen, action, toggle, history, toggleAction, registerForm, loginForm } = props


    return (


        <Modal isOpen={isOpen} centered={true} >
            {
                isOpen &&
                <ModalBody className="px-3 py-3">
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>

                    {
                        action == 'login' &&
                        <>
                            <h3>Connexion</h3>
                            <div className="row">
                                <div className="col-12 form-group floated mb-3">
                                    <Input
                                        name="email"
                                        label="Email"
                                        type="email"
                                        className="form-control"
                                        onChange={loginForm.setFieldValue}
                                        error={loginForm.errors['email']}
                                    />

                                </div>
                                <div className="col-12 form-group floated mb-3">
                                    <Input
                                        name="password"
                                        label="Mot de passe"
                                        type="password"
                                        className="form-control"
                                        onChange={loginForm.setFieldValue}
                                        error={loginForm.errors['password']}
                                    />
                                </div>

                                <div className="col-12 mb-3">
                                    <Link to="/forgot">Mot de passe oublié?</Link>
                                </div>

                                <div className="col-12 mb-3">
                                    <button onClick={loginForm.handleSubmit} className="btn btn-primary btn-lg btn-block">Se connecter</button>
                                </div>

                                <div className="col-12">
                                    <a href="javascript: false" onClick={() => toggleAction('register')}>Pas encore de compte ?</a>
                                </div>
                            </div>
                        </>
                    }
                    {
                        action == 'register' &&
                        <>
                            <h3>Inscription</h3>
                            <div className="row">
                                <div className="form-group col-md-6 col-12 floated mb-3">
                                    <Input
                                        name="nom"
                                        value={registerForm.values['nom']}
                                        type="text"
                                        label="Nom"
                                        className="form-control"
                                        onChange={registerForm.setFieldValue}
                                        error={registerForm.errors['nom']}
                                    />
                                </div>
                                <div className="form-group col-md-6 col-12 floated mb-3">
                                    <Input
                                        label="Prénom"
                                        value={registerForm.values['prenom']}
                                        name="prenom"
                                        type="text"
                                        className="form-control"
                                        onChange={registerForm.setFieldValue}
                                        error={registerForm.errors['prenom']}
                                    />
                                </div>
                                <div className="form-group col-md-6 col-12 floated mb-3">
                                    <Input
                                        label="N° mobile"
                                        value={registerForm.values['telmobile']}
                                        name="telmobile"
                                        type="text"
                                        className="form-control"
                                        onChange={registerForm.setFieldValue}
                                        error={registerForm.errors['telmobile']}
                                    />
                                </div>
                                <div className="form-group col-md-6 col-12 floated mb-3">
                                    <Input
                                        disabled={registerForm.values['disableEmail']}
                                        label="Email"
                                        value={registerForm.values['email']}
                                        name="email"
                                        type="email"
                                        className="form-control"
                                        onChange={registerForm.setFieldValue}
                                        error={registerForm.errors['email']}
                                    />
                                </div>
                                <div className="form-group col-12 floated mb-3">
                                    <Input
                                        label="Code postal"
                                        value={registerForm.values['postal_code']}
                                        name="postal_code"
                                        type="text"
                                        className="form-control"
                                        onChange={registerForm.setFieldValue}
                                        error={registerForm.errors['postal_code']}
                                    />
                                </div>
                                <div className="form-group col-12 floated mb-3">
                                    <Input
                                        label="Mot de passe"
                                        value={registerForm.values['password']}
                                        name="password"
                                        type="password"
                                        className="form-control"
                                        onChange={registerForm.setFieldValue}
                                        error={registerForm.errors['password']}
                                    />
                                </div>
                                <div className="form-group col-12 floated mb-3">
                                    <Input
                                        label="Confirmation mot de passe"
                                        value={registerForm.values['password_confirmation']}
                                        name="password_confirmation"
                                        type="password"
                                        className="form-control"
                                        onChange={registerForm.setFieldValue}
                                        error={registerForm.errors['password_confirmation']}
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <button onClick={registerForm.handleSubmit} className="btn btn-primary btn-lg btn-block">S'inscrire</button>
                                </div>
                                <div className="form-group  mt-3">
                                    <div className="col-12">
                                        <a href="javascript: false" onClick={() => toggleAction('login')}>J'ai déjà un compte</a>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </ModalBody>
            }
        </Modal >
    )
}

const SingleTicketPage = observer((props) => {

    const { match: { params } } = props;
    const [action, setAction] = useState('register')
    const [isOpenAuthModal, setOpenAuthModal] = useState(false)
    const currTicket = TicketStore.props.currTicket
    const loading = TicketStore.props.loading
    let history = useHistory();

    const openModalAuth = (mode) => {
        setAction(mode)
        setOpenAuthModal(true)
    }

    const redirectIfLoginSuccess = () => history.push({
        pathname: '/dashboard',
        state: { name: 'Tableau de bord' }

    })

    const redirectIfRegisterSuccess = (email) => history.push({
        pathname: '/verifyEmail',
        state: { email: email }
    })

    const redirectToVerify = (email) => history.push({
        pathname: '/sendLink',
        state: { email: email }
    })


    const loginForm = useFormik({
        initialValues: {
            email: null,
            password: null,
        },
        onSubmit: values => UserStore.login(values, redirectIfLoginSuccess, redirectToVerify),
        validateOnBlur: false,
        validateOnChange: false
    })


    const registerForm = useFormik({
        initialValues: {
            override: true,
            nom: null,
            prenom: null,
            telmobile: null,
            email: null,
            postal_code: null,
            password: null,
            password_confirmation: null,
        },
        onSubmit: values => UserStore.register(values, registerForm.setErrors),
        validateOnBlur: false,
        validateOnChange: false
    })

    useEffect(() => {
        TicketStore.loadTicket(params.token, registerForm.setFieldValue)
    }, [])


    return (
        <div className='bg-gradient-blue' style={{ minHeight: '100%' }}>
            <div className="container">
                <div className="row ">
                    <div className="col-12 py-3 text-center">
                        <Logo className="white-logo logo-ticket-page"></Logo>
                    </div>
                    <div className="col-12 ticket-container">
                        {/* <SimpleBar style={{maxHeight : '600px'}}> */}
                        {
                            !!currTicket.ticket_url && !loading && !!currTicket.has_image &&

                            <div className="d-flex justify-content-center">
                                <img src={currTicket.ticket_url} alt="barcode" className="barcode img-fluid img-max" />
                            </div>
                        }
                        {
                            !loading && !!currTicket.json &&

                            <div className="d-flex justify-content-center">
                                <div className="ticket-json-container">
                                    <div className="ticket-json">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <img src={currTicket.json.logo} style={{ width: '250px' }}></img>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                                        {currTicket.json.nom}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                                        {currTicket.json.address_1}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                                        {currTicket.json.address_2}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                                        {currTicket.json.postal_code} - {currTicket.json.city}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                                        {currTicket.json.phone}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center', padding: '5px 0px' }}>
                                                        {currTicket.json.receipt_date}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center', padding: '20px 0px' }}>
                                                        Ticket n° {currTicket.json.receipt_number}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center', padding: '20px 0px' }}>
                                                        {currTicket.json.header}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table style={{ width: '100%', margin: 0 }}>
                                            <thead>
                                                <th></th>
                                                <th style={{ textAlign: 'right' }}>TTC(€)</th>
                                                <th style={{ textAlign: 'right' }}>TVA(%)</th>
                                            </thead>
                                            <tbody>
                                                {!!currTicket.json?.ticket?.categories &&
                                                    Object.keys(currTicket.json.ticket.categories).map(key =>
                                                        <>
                                                            <tr>
                                                                <td style={{ fontWeight: 800, padding: '8px 0px 0px 0px' }}>{key}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            {
                                                                currTicket.json.ticket.categories[key].map(item =>
                                                                    <tr>
                                                                        <td style={{ padding: '3px 0px 3px 7px' }}>{item.name}</td>
                                                                        <td style={{ padding: '3px 0px 3px 0px', textAlign: 'right' }}>{numberFormat(item.total_price)}</td>
                                                                        {
                                                                            !!item.tva_applied &&
                                                                            <td style={{ padding: '3px 0px 3px 0px', textAlign: 'right' }}>{numberFormat(item.tva_applied)}</td>
                                                                        }
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    !!currTicket.json?.ticket?.items &&
                                                    currTicket.json.ticket.items.map(item =>
                                                        <tr>
                                                            <td style={{ padding: '3px 0px 3px 7px' }}>{item.name}</td>
                                                            <td style={{ padding: '3px 0px 3px 0px', textAlign: 'right' }}>{numberFormat(item.total_price)}</td>
                                                            {
                                                                !!item.tva_applied &&
                                                                <td style={{ padding: '3px 0px 3px 0px', textAlign: 'right' }}>{numberFormat(item.tva_applied)}</td>
                                                            }
                                                        </tr>
                                                    )
                                                }
                                                <tr>
                                                    <td style={{ padding: '5px 0px 5px 0px' }}></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td colspan="2" style={{ borderTop: '1px dashed #000000' }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ padding: '5px 0px 5px 0px' }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 600 }}>TOTAL HT (€)</td>
                                                    <td style={{ textAlign: 'right' }}>{numberFormat(currTicket.json.ticket.total_ht)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 600 }}>TOTAL TVA (€)</td>
                                                    <td style={{ textAlign: 'right' }}>{numberFormat(currTicket.json.ticket.total_tva)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 600 }}>TOTAL (€)</td>
                                                    <td style={{ textAlign: 'right' }}>{numberFormat(currTicket.json.ticket.total_ttc)}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: 'center', padding: '20px 0px' }}>
                                                        {currTicket.json.footer}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !!currTicket.has_image && !!currTicket.has_json && !loading &&
                            <div className="d-flex">
                                <div className="card shadow">
                                    <div className="card-body">
                                        <div className="col-12 mt-5 text-center">
                                            <Frown size={60} style={{ color: 'rgba(33, 150, 243, 0.4)' }}></Frown>
                                        </div>
                                        <div className="col-12 mt-2 text-center h4">
                                            <span style={{ color: '#607d8b' }}>Ticket non trouvé</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            loading &&
                            <div className="d-flex jc w-100">
                                <div className="card shadow">
                                    <div className="card-body d-flex flex-column jc ac">
                                        <div className="hollow-dots-spinner " >
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                        <div className="loading-text">Chargement du ticket ...</div>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* </SimpleBar> */}
                    </div>
                </div>
                <div className="fixed-bottom w-100 bg-white shadow-lg rounded-top bottom-auth">
                    <div className="">
                        <div className="col-12 px-2 py-2 text-center">
                            Afin de consulter l'historique de vos tickets, veuillez vous authentifier
                        </div>
                        <div className="col-12 px-2 py-2 text-center">
                            <button onClick={() => openModalAuth('login')} className="btn btn-primary btn-sm mx-2">Se connecter</button>
                            <button onClick={() => openModalAuth('register')} className="btn btn-primary btn-sm mx-2">S'inscrire</button>
                        </div>
                    </div>
                </div>
                <AuthModal
                    history={history}
                    action={action}
                    toggle={() => setOpenAuthModal(!isOpenAuthModal)}
                    isOpen={isOpenAuthModal}
                    registerForm={registerForm}
                    toggleAction={(action) => setAction(action)}
                    loginForm={loginForm}
                ></AuthModal>
            </div >
        </div >
    )
})

export default withRouter(SingleTicketPage)