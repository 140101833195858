
import React, { useEffect } from 'react'
import TicketStore from '../../stores/TicketStore';
import DatatableSimple from '../../components/DatatableSimple'
import { observer } from 'mobx-react';
import moment from 'moment';
import 'moment/locale/fr';
import { Modal, ModalBody } from 'reactstrap';
import { ChevronDown, ChevronLeft, ChevronRight, Eye, X } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEye, faHourglassHalf, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { currencyFormat, numberFormat } from '../../utils';
import Input from '../../components/Input';
import { useFormik } from 'formik';
import Logo from '../../components/Logo';

moment.locale('fr');


export const DetailModal = observer((props) => {

    const { isOpen, toggle, currentTicket } = props


    console.log(props)

    return (
        <Modal size="ticket" isOpen={isOpen}>
            {
                isOpen &&
                <ModalBody className="p-0">
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>
                    <div className="ticket w-100">
                        {
                            parseFloat(currentTicket.has_image) > 0 &&
                            <img src={currentTicket.ticket_url} alt="ticket" className="barcode" />
                        }
                        {
                            !!currentTicket.json &&

                            <div className="ticket-json">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'center' }}>
                                                <img src={currentTicket.json.logo} style={{ width: '250px' }}></img>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                                {currentTicket.json.nom}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                                {currentTicket.json.address_1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                                {currentTicket.json.address_2}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                                {currentTicket.json.postal_code} - {currentTicket.json.city}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                                {currentTicket.json.phone}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', padding: '5px 0px' }}>
                                                {currentTicket.json.receipt_date}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', padding: '20px 0px' }}>
                                                Ticket n° {currentTicket.json.receipt_number}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', padding: '20px 0px' }}>
                                                {currentTicket.json.header}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table style={{ width: '100%', margin: 0 }}>
                                    <thead>
                                        <th></th>
                                        <th style={{ textAlign: 'right' }}>TTC(€)</th>
                                        <th style={{ textAlign: 'right' }}>TVA(%)</th>
                                    </thead>
                                    <tbody>
                                        {!!currentTicket.json?.ticket?.categories &&
                                            Object.keys(currentTicket.json.ticket.categories).map(key =>
                                                <>
                                                    <tr>
                                                        <td style={{ fontWeight: 800, padding: '8px 0px 0px 0px' }}>{key}</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    {
                                                        currentTicket.json.ticket.categories[key].map(item =>
                                                            <tr>
                                                                <td style={{ padding: '3px 0px 3px 7px' }}>{item.name}</td>
                                                                <td style={{ padding: '3px 0px 3px 0px', textAlign: 'right' }}>{numberFormat(item.total_price)}</td>
                                                                {
                                                                    !!item.tva_applied &&
                                                                    <td style={{ padding: '3px 0px 3px 0px', textAlign: 'right' }}>{numberFormat(item.tva_applied)}</td>
                                                                }
                                                            </tr>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        {
                                            !!currentTicket.json?.ticket?.items &&
                                            currentTicket.json.ticket.items.map(item =>
                                                <tr>
                                                    <td style={{ padding: '3px 0px 3px 7px' }}>{item.name}</td>
                                                    <td style={{ padding: '3px 0px 3px 0px', textAlign: 'right' }}>{numberFormat(item.total_price)}</td>
                                                    {
                                                        !!item.tva_applied &&
                                                        <td style={{ padding: '3px 0px 3px 0px', textAlign: 'right' }}>{numberFormat(item.tva_applied)}</td>
                                                    }
                                                </tr>
                                            )
                                        }
                                        <tr>
                                            <td style={{ padding: '5px 0px 5px 0px' }}></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td colspan="2" style={{ borderTop: '1px dashed #000000' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '5px 0px 5px 0px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 600 }}>TOTAL HT (€)</td>
                                            <td style={{ textAlign: 'right' }}>{numberFormat(currentTicket.json.ticket.total_ht)}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 600 }}>TOTAL TVA (€)</td>
                                            <td style={{ textAlign: 'right' }}>{numberFormat(currentTicket.json.ticket.total_tva)}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 600 }}>TOTAL (€)</td>
                                            <td style={{ textAlign: 'right' }}>{numberFormat(currentTicket.json.ticket.total_ttc)}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'center', padding: '20px 0px' }}>
                                                {currentTicket.json.footer}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        }
                        {
                            parseFloat(currentTicket.has_image) <= 0 && parseFloat(currentTicket.has_json) <= 0 &&
                            <div className="d-flex flex-column py-5 px-3 ac">
                                <FontAwesomeIcon icon={faHourglassHalf} size="2x" className="text-primary"></FontAwesomeIcon>
                                <h5>Le ticket est en cours de génération ...</h5>
                            </div>
                        }
                    </div>
                </ModalBody>
            }
        </Modal>
    )
});

const TicketTable = observer((props) => {

    const {
        data,
        pagination,
        applyFilter,
        filters,
        toggleDetailModal
    } = props

    const { errors, setFieldValue, handleSubmit, values, setErrors } = useFormik({
        enableReinitialize: true,
        initialValues: filters,
        onSubmit: values => applyFilter(values, setErrors),
        validateOnBlur: false,
        validateOnChange: false
    })


    return (
        <>
            {
                values.created_at &&
                <>
                    <div className="row">
                        <div className="col-md-auto">
                            <div className="form-group floated mb-3">
                                <Input
                                    name="created_at.value.0"
                                    label="Date début"
                                    type="date"
                                    className="form-control"
                                    value={values['created_at']['value'][0]}
                                    onChange={setFieldValue}
                                    error={errors['filters.created_at.value.0']}
                                />

                            </div>
                        </div>
                        <div className="col-md-auto">
                            <div className="form-group floated mb-3">
                                <Input
                                    name="created_at.value.1"
                                    label="Date fin"
                                    type="date"
                                    className="form-control"
                                    value={values['created_at']['value'][1]}
                                    onChange={setFieldValue}
                                    error={errors['filters.created_at.value.1']}
                                />

                            </div>
                        </div>
                        <div className="col-md-auto">
                            <button onClick={handleSubmit} className="btn btn-primary btn-lg btn-block">Rechercher</button>
                        </div>
                    </div>
                    {
                        data.map(item =>
                            <div className="row pt-4 pb-2">
                                <div className="col-3 col-sm-2 col-md-1">
                                    <div className="circle-store">
                                        <FontAwesomeIcon icon={faReceipt} size="lg"></FontAwesomeIcon>
                                    </div>
                                </div>
                                <div className="col-9 col-md-10">
                                    <div className="row ticket-item">
                                        <div className="col-12 col-sm-10 col-md-10 col-lg-10">
                                            <div className="row">
                                                <div className="col-12 font-weight-bold">
                                                    {item.commercant.nom}
                                                </div>
                                                <div className="col-12">
                                                    {moment(item.created_at).format('DD/MM/YYYY')}
                                                </div>

                                            </div>

                                        </div>
                                        <div className="col-12 col-sm-2 col-md-10 col-lg-2 d-flex ac">
                                            <div className="row w-100">
                                                {
                                                    parseFloat(item.has_image) > 0 && parseFloat(item.total_ttc_txt) > 0 &&
                                                    <div className="col-12 d-flex justify-content-end">
                                                        {currencyFormat(item.total_ttc_txt)}
                                                    </div>
                                                }
                                                {
                                                    parseFloat(item.has_json) > 0 && parseFloat(item.total_ttc) > 0 &&
                                                    <div className="col-12 d-flex justify-content-end">
                                                        {currencyFormat(item.total_ttc)}
                                                    </div>
                                                }
                                                {
                                                    (parseFloat(item.has_json) > 0 || parseFloat(item.has_image) > 0) &&
                                                    <div className="col-12 d-flex justify-content-end">
                                                        <button onClick={() => toggleDetailModal(item)} className="btn btn-sm btn-sm-square btn-primary">
                                                            Voir le ticket
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    }
                </>
            }
        </>
    )
})

const TicketPage = observer((props) => {

    useEffect(() => {
        TicketStore.init()
    }, [])

    return (
        <div className="row mt-4">

            <div className="col-12 mb-3">
                <div className="card card-home">
                    <div className="card-body">
                        <h3 className="text-primary">Mes tickets</h3>
                        <div className="row">
                            <div className="col-12">
                                <TicketTable
                                    columns={TicketStore.props.columns}
                                    listActions={TicketStore.props.listActions}
                                    data={TicketStore.props.data}
                                    pagination={TicketStore.props.pagination}
                                    onPaginationChange={(action, index) => TicketStore.onPaginationChange(action, index)}
                                    filters={TicketStore.props.filters}
                                    applyFilter={(values, setErrors) => TicketStore.getFilteredData(values, setErrors)}
                                    toggleDetailModal={(row) => TicketStore.toggleDetailModal(row)}
                                    toggleSort={(column) => TicketStore.toggleSort(column)}
                                    toggleColumn={(column) => TicketStore.toggleColumn(column)}
                                ></TicketTable>
                                <DetailModal
                                    isOpen={TicketStore.props.isOpenDetailModal}
                                    toggle={() => TicketStore.toggleDetailModal()}
                                    currentTicket={TicketStore.props.currentTicket}
                                ></DetailModal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default TicketPage