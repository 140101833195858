import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Frown, Lock, Mail } from 'react-feather'
import Input from '../../components/Input'
import { Link, useHistory } from "react-router-dom";
import Logo from '../../components/Logo';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import UserStore from '../../stores/UserStore';

const LoginPage = observer((props) => {

    const { match: { params } } = props;


    let history = useHistory();

    const redirectIfLoginSuccess = () => history.push({
        pathname: '/dashboard',
        state: { name: 'Tableau de bord' }

    })

    const redirectToVerify = (email) => history.push({
        pathname: '/sendLink',
        state: { email: email }
    })

    const loginForm = useFormik({
        initialValues: {
            email: null,
            password: null,
        },
        onSubmit: values => UserStore.login(values, redirectIfLoginSuccess, redirectToVerify),
        validateOnBlur: false,
        validateOnChange: false
    })


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 min-h-screen shadow bg-gradient-blue  py-5">
                    <div className="row">
                        <div className="col-12 mb-3 text-center">
                            <Logo className="white-logo logo-ticket-page"></Logo>
                        </div>
                        <div className="d-flex jc w-100">
                            <div className="card col-xl-3 col-lg-4 col-md-5 col-sm-4 col-11 px-0">
                                <form className="card-body row">
                                    <div className="col-12 form-group floated mb-3">
                                        <Input
                                            name="email"
                                            label="Email"
                                            type="email"
                                            value={loginForm.values['email']}
                                            className="form-control"
                                            onChange={loginForm.setFieldValue}
                                            error={loginForm.errors['email']}
                                        />

                                    </div>
                                    <div className="col-12 form-group floated mb-3">
                                        <Input
                                            name="password"
                                            label="Mot de passe"
                                            type="password"
                                            value={loginForm.values['password']}
                                            className="form-control"
                                            onChange={loginForm.setFieldValue}
                                            error={loginForm.errors['password']}
                                        />
                                    </div>

                                    <div className="col-12 mb-3">
                                        <Link to="/forgot">Mot de passe oublié?</Link>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <button onClick={loginForm.handleSubmit} className="btn btn-primary btn-lg btn-block">Se connecter</button>
                                    </div>

                                    {/* <div className="col-12">
                                    <Link to="/register">Pas encore de compte ?</Link>
                                </div> */}

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default withRouter(LoginPage)