import { faHourglassHalf, faStore, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react';
import { currencyFormat } from '../../utils';


const GamePage = observer((props) => {

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card card-home" style={{minHeight : '400px'}}>
                    <div className="card-body d-flex ac jc">
                        <div className="row">
                            <div className="col-12 text-center">
                                <FontAwesomeIcon icon={faHourglassHalf} size="2x" className="text-primary"></FontAwesomeIcon>
                            </div>
                            <div className="col-12 text-center">
                                <h3>Coming Soon</h3>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
})

export default GamePage