import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import SelectSearch from 'react-select-search';
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Portal } from 'react-overlays'
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr'

registerLocale('fr', fr)

const Input = (props) => {

    const {
        input,
        type,
        name,
        className,
        placeholder,
        label,
        onChange,
        value,
        error,
        onKeyDown,
        highlight,
        readOnly,
        list,
        fetch,
        minDate,
        disabled,
        depend
    } = props

    const [show, setShow] = useState(false)

    if (type == 'text' || type == 'email') {
        return (
            <>
                {label && <label>{label}</label>}
                <input
                    name={name}
                    placeholder={placeholder}
                    onChange={(e) => onChange(name, e.target.value)}
                    value={value}
                    disabled={disabled}
                    // autoComplete="true"
                    onKeyDown={onKeyDown}
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    type={type} />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'password') {
        return (
            <>
                {label && <label>{label}</label>}
                <input
                    name={name}
                    autoComplete="true"
                    placeholder={placeholder}
                    onChange={(e) => onChange(name, e.target.value)}
                    value={value}
                    onKeyDown={onKeyDown}
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    type={type} />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'switch') {
        return (
            <>
                <div class="custom-control custom-switch">
                    <input
                        type="checkbox"
                        name={name}
                        checked={value > 0}
                        onChange={(e) => { onChange(name, e.target.checked ? 1 : 0) }}
                        class="custom-control-input"
                        id={label + '_switch'} />
                    <label class="custom-control-label" for={label + '_switch'}>{label}</label>
                </div>

            </>
        )
    }

    if (type == 'checkbox') {
        return (
            <>
                <div class="custom-control custom-checkbox">
                    <input id={name} name={name} checked={value > 0}
                        onChange={(e) => onChange(name, e.target.checked ? 1 : 0)}
                        class="custom-control-input" type="checkbox" />
                    <label for={name} className="custom-control-label pointer">{label}</label>
                </div>
            </>
        )
    }

    if (type == 'select') {
        return (
            <>
                {label && <label>{label}</label>}
                <select
                    name={name}
                    onChange={(e) => onChange(name, e.target.value)}
                    value={value}
                    readOnly={readOnly}
                    className={className + classnames({ " is-invalid": (error || highlight) })}>
                    {
                        (list && list.map((item) => <option value={item.value}>{item.label}</option>))
                    }
                </select>
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )

    }

    if (type == 'reactSelect') {
        // console.log(error)
        return (
            <>
                {label && <label>{label}</label>}
                <Select
                    value={value}
                    options={list}
                    // defaultValue={defaultValue}
                    classNamePrefix={className}
                    getOptionValue={item => item.value}
                    onChange={(e) => onChange(name, e)}
                    className={classnames('react-select', { " is-invalid": (error || highlight) })}
                />
                {
                    ((typeof error == "string") && <div className="invalid-feedback">{error}</div>)

                }
                {
                    ((typeof error == "object") && <div className="invalid-feedback">{error.id}</div>)
                }
            </>
        )

    }

    if (type == 'remoteSelect') {



        return (
            <>
                {label && <label>{label}</label>}
                {
                    !show &&
                    <>
                        <span>{value?.label ? value?.label : 'Acune valeur'}</span>
                        <a onClick={() => setShow(!show)}>Changer</a>
                    </>
                }
                {show &&
                    <SelectSearch
                        className={classnames('select-search', { " is-invalid": (error || highlight) })}
                        options={[]}
                        // multiple
                        printOptions="always"
                        debounce={300}
                        value={value}
                        getOptions={fetch}
                        onChange={(val, option) => { onChange(name, option) }}
                        // printOptions="on-focus"
                        search
                        placeholder={placeholder}
                    />
                }
                {
                    ((typeof error == "string") && <div className="invalid-feedback">{error}</div>)

                }
                {
                    ((typeof error == "object") && <div className="invalid-feedback">{error.id}</div>)
                }
            </>
        )

    }

    if (type == 'mySelect') {



        return (
            <>
                {label && <label>{label}</label>}
                <SimpleSelect
                    async={true}
                    fetch={fetch}
                    value={value}
                    error={error}
                    depend={depend}
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    onChange={(val) => { onChange(name, val) }}
                ></SimpleSelect>

            </>
        )

    }


    if (type == 'asyncSelect') {

        // const [search,setSearch] = useState('')

        return (
            <>
                {label && <label>{label}</label>}
                <AsyncSelect
                    // value={value}
                    cacheOptions
                    defaultOptions
                    // defaultValue={defaultValue}
                    getOptionValue={item => item.value}
                    classNamePrefix={className}
                    loadOptions={fetch}
                    // onInputChange={this.handleInputChange}
                    onChange={(e) => onChange(name, e)}
                    className={classnames('react-select', { " is-invalid": (error || highlight) })}
                />
                {
                    ((typeof error == "string") && <div className="invalid-feedback">{error}</div>)

                }
                {
                    ((typeof error == "object") && <div className="invalid-feedback">{error.id}</div>)
                }
            </>
        )
    }

    if (type == 'date') {

        let valueInput = null
        let valueDatePicker = null

        if (moment(value).isValid()) {
            valueDatePicker = moment(value).toDate()
            valueInput = moment(value).format('YYYY-MM-DD')
        }

        const onChangeDate = (date) => {
            return onChange(name, moment(date).format('YYYY-MM-DD'))
        }

        return (
            <>
                <input
                    type="hidden"
                    placeholder={placeholder}
                    value={valueInput}
                    name={name}>

                </input>
                {label && <label>{label}</label>}
                <DatePicker
                    // popperContainer={CalendarContainer}
                    selected={valueDatePicker}
                    onChange={date => onChangeDate(date)}
                    locale="fr"
                    minDate={minDate}
                    dateFormat="dd/MM/yyyy"
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                {
                    (error && <div className="invalid-feedback d-block">{error}</div>)
                }

            </>
        )
    }

    return <div>Field non reconnu</div>

}

export default Input

const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal')

    return (
        <Portal container={el}>
            {children}
        </Portal>
    )
}

const SimpleSelect = (props) => {
    const { list, value, async, fetch, onChange, className,error,depend } = props
    const [search, setSearch] = useState(null)
    const [options, setOptions] = useState(list ? list : [])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const selectRef = useRef()

    const doSearch = async (search) => {
        setSearch(search)
        if (async) {
            setLoading(true)
            setOptions(await fetch(search,depend))
            setLoading(false)
        } else {
            if (search.length)
                setOptions(options.filter(item => item.name.contains(search)))
            else
                setOptions(options)
        }
    }

    const onSelect = async (val) => {
        onChange(val)
        setOpen(false)
    }

    useEffect(() => {
        doSearch()
    }, [depend]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setOpen(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectRef]);

    return (
        <>
            <div ref={selectRef} class="multiselect">
                <div className={"multiselect-input " + classnames(className, { 'focused': open })} onClick={() => setOpen(!open)}>{value?.name}</div>
                {
                    open &&
                    <div class="multiselect-list w-100 rounded">
                        <div className="p-2">
                            <input type="text" placeholder="Recherche ..." className="form-control form-control-sm" value={search} onChange={(e) => doSearch(e.target.value)} />
                        </div>
                        {
                            !loading &&
                            <SimpleBar style={{ maxHeight: '360px' }} className="rounded">
                                <ul className="multiselect-options-list">
                                    {options.length > 0 &&
                                        options.map((item, index) =>
                                            <li
                                                key={index}
                                                className="multiselect-option d-flex ac"
                                                onClick={() => onSelect(item)}
                                            >
                                                {item.name}
                                            </li>
                                        )
                                    }
                                    {
                                        options.length == 0 &&
                                        <li className="text-center">Aucun résultat</li>
                                    }
                                </ul>
                            </SimpleBar>
                        }
                        {
                            loading &&
                            <div className="w-100 text-center py-3">
                                <FontAwesomeIcon icon={faSpinner} size={"lg"} className="fa-spin text-orange mr-2"></FontAwesomeIcon>
                                <span>Chargment ...</span>
                            </div>
                        }
                    </div>
                }
                {
                    (error && <div className="invalid-feedback">{error}</div>)

                }
            </div>
        </>
    )
}