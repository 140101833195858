import { observable, toJS } from 'mobx'
import moment from 'moment'
import { CheckCircle, XCircle } from 'react-feather'
import api from '../api'
import modalAlert from '../components/Alert'
import { numberFormat } from '../utils'
import LoadingStore from './LoadingStore'


const props = {
    cardTotalIsLoading : false,
    cardActivityIsLoading : false,
    cardStatsIsLoading : false,
    lastActivity : [],
    stats : [],
    amountTotal : '--',
}

const DashboardStore = observable({
    /***** Props ******/
    props: props,

    /***** Methods ******/

    async getLastActivity() {
        this.props.cardActivityIsLoading = true
        const res = await api.post('/getLastActivity')
        if (res.data && res.data.success) {
            this.props.lastActivity = res.data.data
        }
        else {
            // modalAlert({
            //     title: 'Erreur',
            //     typeAlert: 'danger',
            //     text: "Une erreur est survenue"
            // })
        }
        this.props.cardActivityIsLoading = false
    },
    async getTotal() {
        this.props.cardTotalIsLoading = true
        const res = await api.post('/getTotal')
        if (res.data && res.data.success) {
            this.props.amountTotal = res.data.data
        }
        else {
            // modalAlert({
            //     title: 'Erreur',
            //     typeAlert: 'danger',
            //     text: "Une erreur est survenue"
            // })
        }
        this.props.cardTotalIsLoading = false
    },
    async getStats() {
        this.props.cardStatsIsLoading = true
        const res = await api.post('/getStats')
        if (res.data && res.data.success) {
            // this.props.stats = res.data.data
            let labels = []
            let data = []
            for(let month in res.data.data){
                labels.push(month)
                console.log(res.data.data[month])
                data.push(res.data.data[month])
            }


            this.props.stats = {
                labels: labels,
                datasets: [
                    {
                        label: 'Nombre ticket',
                        data: data,
                        fill: false,
                        backgroundColor: '#fff',
                        borderColor: '#fff',
                    },
                ],
            }
        }
        else {
            // modalAlert({
            //     title: 'Erreur',
            //     typeAlert: 'danger',
            //     text: "Une erreur est survenue"
            // })
        }
        this.props.cardStatsIsLoading = false
    },
    reset() {
        console.log('RESET STORE')
        this.props = props
    }
})


export default DashboardStore