import { faBarcode, faReceipt, faStore, faUser, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import UserStore from '../../stores/UserStore'
import { Bar } from 'react-chartjs-2';
import { LogoJeu } from '../../assets/img/jeux.jpg'
import TicketStore from '../../stores/TicketStore';
import DashboardStore from '../../stores/DashboardSstore';
import { LoadingOverlay } from '../../components/Loading';
import { observer } from 'mobx-react';
import { currencyFormat, numberFormat } from '../../utils';
import moment from 'moment';
import 'moment/locale/fr';
import Barcode from 'react-barcode';

moment.locale('fr');

const DashboardPage = observer((props) => {

    const cardTotalIsLoading = DashboardStore.props.cardTotalIsLoading
    const cardActivityIsLoading = DashboardStore.props.cardActivityIsLoading
    const cardStatsIsLoading = DashboardStore.props.cardStatsIsLoading
    const amountTotal = DashboardStore.props.amountTotal
    const lastActivity = DashboardStore.props.lastActivity
    const stats = DashboardStore.props.stats

    const user = UserStore.props.user

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
                position: 'bottom',
                labels: {
                    color: '#fff',
                    usePointStyle: true,
                }
            },
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    drawOnChartArea: false,
                },
                ticks: {
                    color: '#fff',
                    maxTicksLimit: 3,
                    maxRotation: 0
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    // maxTicksLimit: 10,
                    stepSize: 1,
                    color: '#fff',
                    callback: function (value) {
                        return value
                    }
                }
            }
        },
        elements: {
            point: {
                radius: 2,
                borderWidth: 2,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3
            }
        }
    }

    console.log(lastActivity)
    useEffect(() => {
        DashboardStore.getLastActivity()
        DashboardStore.getTotal()
        DashboardStore.getStats()
    }, [])

    return (
        <div className="row mt-4">
            <div className="col-md-6 col-12">
                <div className="row">
                    <div className="col-12">
                        <h4>Bonjour, {user.prenom}</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="card card-home">
                            <LoadingOverlay loading={cardTotalIsLoading}></LoadingOverlay>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h5> <FontAwesomeIcon icon={faReceipt} className="text-primary mr-2"></FontAwesomeIcon>Total</h5>
                                    </div>
                                    <div className="col-12">
                                        <span className="h1 font-weight-light">{numberFormat(amountTotal)} {amountTotal != 1 ? 'tickets' : 'ticket'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="card card-home">
                            <LoadingOverlay loading={cardTotalIsLoading}></LoadingOverlay>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h5> <FontAwesomeIcon icon={faBarcode} className="text-primary mr-2"></FontAwesomeIcon>Mon code barre</h5>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <Barcode format='EAN13' lineColor="#2c2e2f" value={user.ean} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12  mb-3">
                        <div className="card card-home bg-gradient-blue">
                            <LoadingOverlay loading={cardStatsIsLoading}></LoadingOverlay>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="text-white">Statistiques mensuelles</h5>
                                    </div>
                                    <div className="col-12">
                                        {
                                            stats.labels && stats.labels.length > 0 &&
                                            <Bar data={stats} options={options} />
                                        }
                                        {
                                            stats.labels && stats.labels.length == 0 &&
                                            <h3 className="text-center text-white">Aucune donnée disponible</h3>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12  mb-3">
                        <div className="card card-home">
                            <div className="d-flex flex-column">
                                <div className="w-100 img-jeux" style={{ height: '200px', backgroundImage: 'url(https://www.starwax.fr/medias/banniere-concours-2018-10-bible-entretien-menager.jpg)' }}>

                                </div>
                                <div className="w-100 py-2 px-3 font-weight-bold">
                                    <a href="#" className="py-3">Jouer</a>
                                </div>

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="col-md-6 col-12">
                <div className="row">
                    <div className="col-12">
                        <h5 className="text-primary">Activité récente</h5>
                        <table className="table w-100">
                            {
                                lastActivity && lastActivity.length == 0 &&
                                <h3 className="text-center">Aucun ticket disponible</h3>
                            }
                            {
                                lastActivity.map((item) =>
                                    <tr>
                                        <td>
                                            <div className="circle-store">
                                                <FontAwesomeIcon icon={faStore} size="lg"></FontAwesomeIcon>
                                            </div>
                                        </td>
                                        <td>{moment(item.created_at).fromNow()}</td>
                                        <td>{currencyFormat(item.total_ttc_txt)}</td>
                                        <td>{item.commercant.nom}</td>
                                        <td></td>
                                    </tr>
                                )
                            }

                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default DashboardPage