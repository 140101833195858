import { useFormik } from 'formik'
import React from 'react'
import { Lock, Mail } from 'react-feather'
import Input from '../../components/Input'
import UserStore from '../../stores/UserStore'
import Logo from '../../components/Logo'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'

const ForgotPasswordPage = observer((props) => {

    const { errors, setErrors, setFieldValue, handleSubmit } = useFormik({
        initialValues: {
            email: null,
        },
        onSubmit: values => UserStore.forgot(values, setErrors),
        validateOnBlur: false,
        validateOnChange: false
    })

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 min-h-screen shadow bg-gradient-blue  py-5">
                    <div className="row">
                        <div className="col-12 mb-3 text-center">
                            <Logo className="white-logo logo-ticket-page"></Logo>
                        </div>
                        <div className="d-flex jc w-100">
                            <div className="card col-xl-3 col-lg-4 col-md-5 col-sm-4 col-11 px-0">
                                <form className="card-body row">
                                    <div className="col-12 form-group floated mb-3">
                                        <p className="text-center">Afin de de recevoir votre lien de réinitialisation, veuillez saisir votre adresse email</p>
                                    </div>
                                    <div className="col-12 form-group floated mb-3">
                                        <Input
                                            name="email"
                                            label="Email"
                                            type="email"
                                            className="form-control"
                                            onChange={setFieldValue}
                                            error={errors['email']}
                                        />

                                    </div>

                                    <div className="col-12 mb-3">
                                        <button onClick={handleSubmit} className="btn btn-primary btn-lg btn-block">Envoyer le lien de réinitialisation</button>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <Link to="/login">Retour</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
})

export default ForgotPasswordPage