import React, { useState, useEffect } from 'react'

import {
    BrowserRouter as Router,
    // HashRouter,
    Route,
    Switch
} from "react-router-dom";

import SingleTicketPage from '../pages/Ticket/SingleTicketPage';
import IsNotAuthenticated from "../middlewares/IsNotAuthenticated";
import IsAuthenticated from "../middlewares/IsAuthenticated";
import IsNotRegistered from "../middlewares/IsNotRegistered";
import DashboardPage from "../pages/Dashboard/DashboardPage";
import LoginPage from "../pages/Auth/LoginPage";
import LogoutPage from "../pages/Logout/LogoutPage";
import NavbarLayout from '../pages/Layouts/NavbarLayout';
import TicketPage from "../pages/Ticket/TicketPage";
import ForgotPasswordPage from "../pages/ForgotPassword/ForgotPasswordPage";
import ResetPasswordPage from "../pages/ForgotPassword/ResetPasswordPage";
import GamePage from "../pages/Game/GamePage";
import RegisterMobilePage from "../mobile/Auth/RegisterMobilePage";
import RegisterPage from "../pages/Auth/RegisterPage";
import LoadingStore from '../stores/LoadingStore';
import UserStore from '../stores/UserStore';
import IsNotVerified from '../middlewares/IsNotVerified';
import SurveyPage from '../pages/Survey/SurveyPage';

export const history = require("history").createBrowserHistory();

const AppRoute = (() => {

    console.log(history)

    const [ready, setReady] = useState(false)

    useEffect(async () => {
        await UserStore.init()
        setReady(true)
    }, [])



    return (
        <>
            {
                ready &&

                <Router history={history}>
                    <Switch>
                        <Route exact path="/login">
                            <IsNotAuthenticated>
                                <LoginPage></LoginPage>
                            </IsNotAuthenticated>
                        </Route>
                        <Route exact path="/register">
                            <IsNotAuthenticated>
                                <RegisterPage></RegisterPage>
                            </IsNotAuthenticated>
                        </Route>
                        <Route exact path="/forgot">
                            <IsNotAuthenticated>
                                <ForgotPasswordPage></ForgotPasswordPage>
                            </IsNotAuthenticated>
                        </Route>
                        <Route exact path="/reset-password/:token">
                            <IsNotAuthenticated>
                                <ResetPasswordPage></ResetPasswordPage>
                            </IsNotAuthenticated>
                        </Route>
                        <Route exact path="/ticket/read/:token">
                            <IsNotAuthenticated>
                                <SingleTicketPage></SingleTicketPage>
                            </IsNotAuthenticated>
                        </Route>
                        <Route exact path="/client/registerMobile/:token">
                            <RegisterMobilePage></RegisterMobilePage>
                        </Route>
                        <Route exact path={["/", "/dashboard"]} name="Dashboard">
                            <IsAuthenticated>
                                <NavbarLayout>
                                    <DashboardPage></DashboardPage>
                                </NavbarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path="/ticket" name="Dashboard">
                            <IsAuthenticated>
                                <NavbarLayout>
                                    <TicketPage></TicketPage>
                                </NavbarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path="/jeux" name="Jeux">
                            <IsAuthenticated>
                                <NavbarLayout>
                                    <GamePage></GamePage>
                                </NavbarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path={"/logout"} name="Déconnexion">
                            <IsAuthenticated>
                                <NavbarLayout>
                                    <LogoutPage></LogoutPage>
                                </NavbarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path="/survey/:id/:token">
                            <SurveyPage></SurveyPage>
                        </Route>
                    </Switch>
                </Router>
            }
        </>
    );
})


export default AppRoute;
