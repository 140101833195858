import { observable } from 'mobx'


const props = {
    loading: 0
}

const LoadingStore = {

    props: props,

    /***** Methods ******/
    on() {
        this.props.loading++
        // console.log('Load +1')
    },
    off() {
        this.props.loading--
        // console.log('Load -1')
    },
    reset() {
        this.props = props
    }
}

export default observable(LoadingStore)