import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { Frown, CheckCircle } from 'react-feather'
import Input from '../../components/Input'
import { Link, useHistory } from "react-router-dom";
import Logo from '../../components/Logo';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import UserStore from '../../stores/UserStore';

const RegisterMobilePage = observer((props) => {

    const { match: { params } } = props;
    const emailToRegister = UserStore.props.emailToRegister
    const successMobileRegister = UserStore.props.successMobileRegister
    const loading = UserStore.props.loading
    let history = useHistory();

    const redirectIfRegisterSuccess = (email) => history.push({
        pathname: '/verifyEmail',
        state: { email: email }
    })


    const registerForm = useFormik({
        initialValues: {
            // override : true,
            mobile : true,
            token: params.token,
            nom: null,
            prenom: null,
            telmobile: null,
            email: null,
            postal_code: null,
            password: null,
            password_confirmation: null,
        },
        onSubmit: values => UserStore.register(values, registerForm.setErrors),
        validateOnBlur: false,
        validateOnChange: false
    })

    useEffect(() => {
        UserStore.loadUserForRegister(params.token, registerForm.setFieldValue)
    }, [])


    return (
        <div className="row">
            <div className="col-md-12 min-h-screen shadow bg-gradient-blue  py-5">
                <div className="row">
                    <div className="col-12 mb-3 text-center">
                        <Logo className="white-logo logo-ticket-page"></Logo>
                    </div>
                    <div className="d-flex jc w-100">
                        {
                            !successMobileRegister && emailToRegister && !loading &&
                            <div className="card col-xl-5 col-lg-5 col-md-5 col-sm-11 col-11 px-0">
                                <form className="card-body row">
                                    <div className="form-group col-12 floated mb-3">
                                        <h3 className="text-primary">Activation compte mobile</h3>
                                    </div>
                                    <div className="form-group col-md-6 col-12 floated mb-3">
                                        <Input
                                            name="nom"
                                            value={registerForm.values['nom']}
                                            type="text"
                                            label="Nom"
                                            className="form-control"
                                            onChange={registerForm.setFieldValue}
                                            error={registerForm.errors['nom']}
                                        />
                                    </div>
                                    <div className="form-group col-md-6 col-12 floated mb-3">
                                        <Input
                                            label="Prénom"
                                            value={registerForm.values['prenom']}
                                            name="prenom"
                                            type="text"
                                            className="form-control"
                                            onChange={registerForm.setFieldValue}
                                            error={registerForm.errors['prenom']}
                                        />
                                    </div>
                                    <div className="form-group col-md-6 col-12 floated mb-3">
                                        <Input
                                            label="N° mobile"
                                            value={registerForm.values['telmobile']}
                                            name="telmobile"
                                            type="text"
                                            className="form-control"
                                            onChange={registerForm.setFieldValue}
                                            error={registerForm.errors['telmobile']}
                                        />
                                    </div>
                                    <div className="form-group col-md-6 col-12 floated mb-3">
                                        <Input
                                            disabled={true}
                                            label="Email"
                                            value={registerForm.values['email']}
                                            name="email"
                                            type="email"
                                            className="form-control"
                                            onChange={registerForm.setFieldValue}
                                            error={registerForm.errors['email']}
                                        />
                                    </div>
                                    <div className="form-group col-12 floated mb-3">
                                        <Input
                                            label="Code postal"
                                            value={registerForm.values['postal_code']}
                                            name="postal_code"
                                            type="text"
                                            className="form-control"
                                            onChange={registerForm.setFieldValue}
                                            error={registerForm.errors['postal_code']}
                                        />
                                    </div>
                                    <div className="form-group col-12 floated mb-3">
                                        <Input
                                            label="Mot de passe"
                                            value={registerForm.values['password']}
                                            name="password"
                                            type="password"
                                            className="form-control"
                                            onChange={registerForm.setFieldValue}
                                            error={registerForm.errors['password']}
                                        />
                                    </div>
                                    <div className="form-group col-12 floated mb-3">
                                        <Input
                                            label="Confirmation mot de passe"
                                            value={registerForm.values['password_confirmation']}
                                            name="password_confirmation"
                                            type="password"
                                            className="form-control"
                                            onChange={registerForm.setFieldValue}
                                            error={registerForm.errors['password_confirmation']}
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <button onClick={registerForm.handleSubmit} className="btn btn-primary btn-lg btn-block">S'inscrire</button>
                                    </div>
                                </form>

                            </div>
                        }
                        {
                            !emailToRegister && !loading &&
                            <div className="d-flex">
                                <div className="card shadow">
                                    <div className="card-body">
                                        <div className="col-12 mt-5 text-center">
                                            <Frown size={60} style={{ color: 'rgba(33, 150, 243, 0.4)' }}></Frown>
                                        </div>
                                        <div className="col-12 mt-2 text-center h4">
                                            <span style={{ color: '#607d8b' }}>Email non trouvé</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            loading &&
                            <div className="d-flex">
                                <div className="card shadow">
                                    <div className="card-body d-flex flex-column jc ac">
                                        <div className="hollow-dots-spinner " >
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                        <div className="loading-text">Chargement des informations ...</div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            successMobileRegister && !loading &&
                            <div className="d-flex">
                                <div className="card shadow">
                                    <div className="card-body">
                                        <div className="col-12 mt-5 text-center">
                                            <CheckCircle size={60} className="text-success"></CheckCircle>
                                        </div>
                                        <div className="col-12 mt-2 text-center h4">
                                            <span style={{ color: '#607d8b' }}>Inscription réussie</span>
                                        </div>
                                        <div className="col-12 mt-2 text-center">
                                            Vous pouvez désormais accéder a vos tickets via l'application mobile
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})

export default withRouter(RegisterMobilePage)