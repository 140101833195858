import { faHourglassHalf, faStore, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react';
import { currencyFormat } from '../../utils';
import { withRouter } from 'react-router';
import * as Survey from "survey-react";
import "survey-react/modern.css";
import SurveyStore from '../../stores/SurveyStore';
import { render } from 'react-dom';

Survey.StylesManager.applyTheme("modern");
Survey.locale = "fr";
Survey.surveyLocalization.defaultLocale = "fr";
Survey.surveyLocalization.defaultLocale = "fr";
const myloc = Survey.surveyLocalization.getCurrentStrings();
myloc.completingSurvey = "Merci d’avoir répondu à notre enquête !"


const SurveyPage = observer((props) => {

    const { match: { params } } = props;

    const submitSurvey = (data, id, token) => SurveyStore.submitSurvey(data, id, token)

    let survey = null

    useEffect(() => {
        const load = async () => {
            const json = await SurveyStore.loadSurvey(params.token)
            console.log(JSON.parse(json))
            survey = new Survey.Model(JSON.parse(json))
            survey.onComplete.add(function (sender) {
                submitSurvey(JSON.stringify(sender.data), params.id, params.token)
            });
            survey.completedHtml = '<h3>Merci d\'avoir répondu a notre questionnaire</h3>'
            render(<Survey.Survey model={survey} />, document.getElementById("surveyModelContainer"));
            // survey.render("surveyModelContainer");
        }

        load()
    }, [])


    return (
        <div className="mt-4 mx-4 pb-4">
            <div className="col-12">
                <div className="card card-home" style={{ minHeight: '400px' }}>
                    <div className="card-body">
                        <div className="w-100">
                            <div id="surveyModelContainer"></div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
})

export default withRouter(SurveyPage)