import { observable, toJS } from 'mobx'
import moment from 'moment'
import { CheckCircle, XCircle } from 'react-feather'
import api from '../api'
import modalAlert from '../components/Alert'
import { currencyFormat } from '../utils'
import LoadingStore from './LoadingStore'


const props = {
    pageReady: false,
    loading: false,
    currentTicket: {},
    columns: [],
    filters: {},
    activeFilters: {},
    currentRow: {},
    currTicket: {},
    data: [],
    pagination: {},
    isOpenDetailModal: false,
}

const TicketStore = observable({
    /***** Props ******/
    props: props,
    /***** Filter ******/
    async getFilteredData(values, setErrors) {
        // console.log(toJS(values))
        this.props.activeFilters = values
        await this.loadData(setErrors)
    },
    /***** Sort ******/
    toggleSort(column) {
        // console.log(column)
        for (let key in this.props.activeFilters) {

            if (column == key) {

                if (!this.props.activeFilters[key].sort) {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = 'ASC'
                    // break
                }
                else if (this.props.activeFilters[key].sort == 'ASC') {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = 'DESC'
                    // break
                }
                else if (this.props.activeFilters[key].sort == 'DESC') {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = null
                    // break
                }
            }
            else {
                this.props.activeFilters[key].sort = null
            }

        }

        this.loadData()
        console.log(toJS(this.props.filters))
    },
    /***** Toggle Columns ******/
    toggleColumn(column) {
        console.log('toggle')
        this.props.columns.map((item, index) => {
            if (item.key == column.key) {
                this.props.columns[index].isHidden = !this.props.columns[index].isHidden
                console.log(this.props.columns[index].isHidden)

            }
        })
    },
    /***** Pagination change ******/
    onPaginationChange(action, index) {
        switch (action) {
            case 'next':
                this.props.pagination.index++
                this.loadData()
                break;
            case 'prev':
                if ((this.props.pagination.index - 1) >= 0)
                    this.props.pagination.index--
                this.loadData()
                break;
            case 'goto':
                this.props.pagination.index = index
                this.loadData()
                break;

            default:
                break;
        }

    },
    /***** Initialisation ******/
    async init() {

        // Initialisation des columns 
        this.props.columns = [
            {
                key: 'no_ticket',
                label: 'N° Ticket',
                field: 'no_ticket',
            },
            {
                label: 'Commerçant',
                key: 'commercant',
                field: 'commercant',
                render: (value) => {
                    return <span className="font-weight-bold">{value.nom}</span>
                }
            },
            {
                key: 'date',
                label: 'Créée le',
                field: 'date',
                render: (value) => moment(value).format('DD/MM/YYYY')
            },
            {
                key: 'tva',
                label: 'TVA',
                field: 'tva',
                render: (value) => currencyFormat(value)
            },
            {
                key: 'total_ttc_txt',
                label: 'Montant TTC',
                field: 'total_ttc_txt',
                render: (value) => currencyFormat(value)
            },
            {
                key: 'total_ttc',
                label: 'Montant TTC',
                field: 'total_ttc',
                render: (value) => currencyFormat(value)
            },
            {
                key: 'actions',
                label: 'Actions',
                listActions: [
                    {
                        label: 'Voir détails',
                        name: 'details',
                        action: (row) => this.toggleDetailModal(row)
                    }
                ]
            }
        ]

        // Initialisation des filtres
        this.props.filters = {
            created_at: {
                active: true,
                isSortable: true,
                label: 'Date création',
                type: 'date',
                operator: 'between',
                value: [moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
                sort: null
            }
        }

        //Initialisation pagination
        this.props.pagination = {
            index: 1,
            offset: 6,
            total: null,
            onPaginationChange: (action, index) => this.onPaginationChange(action, index)
        }

        this.props.activeFilters = this.props.filters
        await this.loadData()
        this.props.pageReady = true
    },
    /***** Methods ******/
    async loadTicket(token, setFieldValue) {
        this.props.loading = true
        const res = await api.post('/getTicketByToken', { token: token })
        if (res.data && res.data.success) {
            if (res.data.found) {
                this.props.currTicket = res.data
                setFieldValue('nom', res.data.nom)
                setFieldValue('prenom', res.data.prenom)
                setFieldValue('postal_code', res.data.postal_code)
                setFieldValue('email', res.data.email)
                setFieldValue('disableEmail', res.data.email ? true : false)
                setFieldValue('telmobile', res.data.telmobile)
            }
            else
                this.props.currTicket = {}
        }
        else {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        this.props.loading = false
    },
    async toggleDetailModal(row) {
        // console.log(row)
        this.props.currentTicket = row ? row : {}
        if (row)
            this.props.currentTicket = await this.fetchTicketById(row)
        this.props.isOpenDetailModal = !this.props.isOpenDetailModal
    },
    async loadData(setErrors = null) {
        LoadingStore.on()
        const res = await api.post('/ticket/list', { pagination: this.props.pagination, filters: this.props.activeFilters })
        if (res.data && res.data.success) {
            console.log(res.data.data)
            this.props.data = res.data.data
            this.props.pagination.total = res.data.total
        }
        else if (res.data.errors && setErrors) {
            setErrors(res.data.errors)
        }
        else {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        LoadingStore.off()
    },
    async fetchTicketById(row) {
        LoadingStore.on()
        const res = await api.post('/ticket/getTicketById', { id: row.id })
        if (res.data && res.data.success) {
            LoadingStore.off()
            return res.data
        }
        else {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            LoadingStore.off()
            return []
        }
    },
    async submitTicket(values, setErrors) {
        LoadingStore.on()
        const res = await api.post('/commercant/store', values)
        if (res.data && res.data.success) {
            this.toggleDetailModal()
            modalAlert({
                title: 'Succes',
                typeAlert: 'success',
                text: res.data.msg
            })
            await this.loadData()
        }
        else if (res.data.errors) {
            setErrors(res.data.errors)
        }
        else {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        LoadingStore.off()
    },
    reset() {
        console.log('RESET STORE')
        this.props = props
    }
})


export default TicketStore