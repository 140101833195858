import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Redirect, Route,withRouter } from 'react-router-dom'
import UserStore from '../stores/UserStore'

const IsNotRegistered = observer((props) => {

    const isRegistered = UserStore.props.user.isRegistered
    const { children } = props

    useEffect(() => {
        UserStore.init()
    }, [props.location])

    console.log('Middleware ISNOTREGISTERED',!isRegistered)

    return <Route
        render={({ location }) => {
            if (!isRegistered) {
                return children
            } else {
                return <Redirect
                    to={{
                        pathname: "/dashboard",
                        state: { from: location }
                    }}
                />
            }
        }
        }></Route>
})

export default IsNotRegistered