
import React, { useState } from 'react'
import { ChevronDown, Lock, LogOut, Menu, User } from 'react-feather'
import { NavLink, Link, withRouter } from 'react-router-dom'
import Logo from '../../components/Logo'
import {
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem
} from 'reactstrap'
import UserStore from '../../stores/UserStore'
import AppMenu from '../../components/AppMenu'
import { observer } from 'mobx-react'


const NavbarLayout = observer(withRouter((props) => {

    const { children } = props
    // console.log(props)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const [isOpen, setIsOpen] = useState(false);
    const toggleNav = () => setIsOpen(!isOpen);


    return (

        <>
            <Navbar className="bg-gradient-blue" expand="lg">
                <div className="container">
                    <NavLink to="/" className="brand-link text-center">
                        <Logo className="white-logo logo-client"></Logo>
                    </NavLink>
                    <NavbarToggler onClick={toggleNav}>
                        <Menu size="30" color="#fff"></Menu>
                    </NavbarToggler>
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <AppMenu></AppMenu>
                        </Nav>
                        <Nav className="ml-auto" navbar>
                            <li className="nav-item">
                                <NavLink to={{pathname : '/logout'}} className="nav-link" activeClassName="active">
                                    <LogOut size="18" className="mr-2"></LogOut>
                                    Se déconnecter
                                </NavLink>
                            </li>
                        </Nav>
                    </Collapse>
                </div>
            </Navbar>

            <div className="container-fluid">
                {children}
            </div>
        </>

    )

}))


export default NavbarLayout