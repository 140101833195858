import { observable, toJS } from 'mobx'
import moment from 'moment'
import api from '../api'
import modalAlert from '../components/Alert'
import { currencyFormat } from '../utils'
import LoadingStore from './LoadingStore'


const props = {
    pageReady: false,
    currSurvey: false,
    loading: false,
}

const SurveyStore = observable({
    /***** Props ******/
    props: props,
    /***** Initialisation ******/
    async init() {

        this.props.pageReady = true
    },
    /***** Methods ******/
    async loadSurvey(token, setFieldValue) {
        this.props.loading = true
        const res = await api.post('/getSurveyByToken', { token: token })
        if (res.data && res.data.success) {
            if (res.data.found) {
                return res.data.json
            }
            else
                return null
        }
        else {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })

        }
        this.props.loading = false
        return null
    },
    async submitSurvey(json, id, token) {
        LoadingStore.on()
        // console.log({ id: id, token: token, json: json })
        const res = await api.post('/storeSurveyAnswer', { id: id, token: token, json: json })
        if (res.data && res.data.success) {
            modalAlert({
                title: 'Succes',
                typeAlert: 'success',
                text: res.data.msg
            })
        }
        else {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        LoadingStore.off()
    },
    reset() {
        console.log('RESET STORE')
        this.props = props
    }
})


export default SurveyStore